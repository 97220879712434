import React from 'react'
import { Space } from 'antd'
import StudentsDrawer from '../students-drawer/students-drawer'
import { IStudent } from '../../../../interfaces'
import StudentsAddToGroup from '../students-add-to-group/students-add-to-group'
import { useRoleChecker } from '../../../../hooks/useRoleChecker'

interface props {
  student: IStudent
}

const StudentActions: React.FC<props> = ({ student }) => {
  const { roleChecker } = useRoleChecker()
  const access = roleChecker(['admin', 'reception'])

  return (
    <Space>
      {access && <StudentsAddToGroup hideText={true} studentId={student.id} />}
      <StudentsDrawer title={'editTitle'} student={student} />
    </Space>
  )
}

export default React.memo(StudentActions)
